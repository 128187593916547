body {
  margin: 0;
  font-family: "Gotham Book", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: white;
}

@font-face {
  font-family: "Gotham Book";
  font-style: normal;
  font-weight: 400;
  src: url("../public/fonts/Gotham Book.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Book";
  font-weight: 700;
  src: url("../public/fonts/Gotham Bold.otf") format("opentype");
}

@font-face {
  font-family: "Gotham Book";
  font-weight: 900;
  src: url("../public/fonts/Gotham Ultra.otf") format("opentype");
}
  

